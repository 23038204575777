import React from 'react';
import style from './style.css';

const Header = () => (
	<header className={style.header}>
		<p> Made for Sam Son Hotel Document Parser </p>
	</header>
);

export default Header;
